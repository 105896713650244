import { CustomData, DetailDeviceLabels } from '../../models/device-detail.model';
import { detailDeviceLabels } from '../devices-detail.labels';

const labelsDetail: DetailDeviceLabels = detailDeviceLabels;

export const defaultCardPosition: CustomData[] = [
  {
    expanded: false,
    hidden: false,
    hiddenDevice: false,
    layout: {
      groups: {
        visible: true,
        expanded: true
      },
      tags: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.generalInfo,
    type: 'info'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: false,
    layout: {
      main: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.config,
    type: 'config'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: false,
    layout: {
      network: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.network,
    type: 'network'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: false,
    layout: {
      analytics: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.analytics,
    type: 'analytics'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: false,
    layout: {
      incompleteCommands: {
        visible: true,
        expanded: true
      },
      telemetry: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.telemetry,
    type: 'telemetry'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: true,
    layout: {
      incompleteCommands: {
        visible: true,
        expanded: true
      },
      credentials: {
        visible: false,
        expanded: false
      }
    },
    title: labelsDetail.credentials,
    type: 'credentials'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: true,
    layout: {
      incompleteCommands: {
        visible: true,
        expanded: true
      },
      secrets: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.secrets,
    type: 'secrets'
  },
  {
    expanded: false,
    hidden: false,
    hiddenDevice: true,
    layout: {
      incompleteCommands: {
        visible: true,
        expanded: true
      },
      volumes: {
        visible: true,
        expanded: true
      }
    },
    title: labelsDetail.dockerVolumes,
    type: 'volumes'
  }
];

export interface ModelStratus {
  nodes: {
    name: string;
    model: string;
    state: string;
    activity: string;
    vmCount: string;
    isPrimary: string;
    cpu: any;
    ram: any;
  }[];
  cluster: {
    cpu: {
      maxCpu: number;
      vmCpu: number;
      percentageVmCpu: number;
    };
    disk: {
      maxDisk: number;
      vmDisk: number;
      vmDiskSystem: number;
      percentageVmDisk: number;
      percentageVmDiskSystem: number;
      vmVCDsDisk: number;
      percentageVmCVDsDisk: number;
    };
    ram: {
      maxRam: number;
      vmRam: number;
      vmRamSystem: number;
      percentageVmRam: number;
      percentageVmRamSystem: number;
    };
  };
  lastUpdate: string;
}

export interface ModelStratusVirtual {
  virtualNodes: {
    location: string;
    name: string;
    status: string;
  }[];
  lastUpdate: string;
}

export interface sendObjectNative {
  data: {
    jsonConfig: string;
    applicationId: string;
    appVersionId: string;
    runNative: boolean;
    enableLogs: boolean;
  };
}

export const CreateCardInfo: CustomData = {
  expanded: false,
  hidden: false,
  hiddenDevice: false,
  layout: {
    groups: {
      visible: true,
      expanded: true
    },
    tags: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.generalInfo,
  type: 'info'
};
export const CreateCardAppConfig: CustomData = {
  expanded: false,
  hidden: false,
  hiddenDevice: false,
  layout: {
    main: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.config,
  type: 'config'
};
export const CreateCardNetwork: CustomData = {
  expanded: false,
  hidden: false,
  hiddenDevice: false,
  layout: {
    network: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.network,
  type: 'network'
};
export const CreateCardAnalytics: CustomData = {
  expanded: false,
  hidden: false,
  hiddenDevice: false,
  layout: {
    analytics: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.analytics,
  type: 'analytics'
};
export const CreateCardTelemetry: CustomData = {
  expanded: false,
  hidden: false,
  hiddenDevice: false,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    telemetry: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.telemetry,
  type: 'telemetry'
};
export const CreateCardCredential: CustomData = {
  expanded: false,
  hidden: true,
  hiddenDevice: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    credentials: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.credentials,
  type: 'credentials'
};
export const CreateCardSecret: CustomData = {
  expanded: false,
  hidden: true,
  hiddenDevice: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    secrets: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.secrets,
  type: 'secrets'
};
export const CreateCardVolumes: CustomData = {
  expanded: false,
  hidden: true,
  hiddenDevice: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    volumes: {
      visible: false,
      expanded: true
    }
  },
  title: labelsDetail.dockerVolumes,
  type: 'volumes'
};

export const CreateCardNative: CustomData = {
  id: '',
  spaceId: 'native',
  hidden: false,
  hiddenDevice: false,
  expanded: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    appconfig: {
      visible: true,
      editable: true,
      expanded: true
    },
    status: {
      visible: true,
      expanded: true
    },
    log: {
      visible: true,
      expanded: true,
      enable: true,
      follow: true,
      wrap: false,
      time: false,
      darkMode: true
    },
    persist: {
      visible: false,
      expanded: true
    }
  },
  title: labelsDetail.appNative,
  type: 'native'
};
export const CreateCardStratusVirtual: CustomData = {
  id: '',
  spaceId: '',
  hidden: false,
  hiddenDevice: false,
  expanded: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    status: {
      visible: true,
      expanded: true
    },
    nodes: {
      visible: true,
      expanded: true
    },
    clusterLevel: {
      visible: true,
      expanded: true
    },
    virtualMachines: {
      visible: true,
      expanded: true
    },
    log: {
      visible: false,
      expanded: true,
      enable: true,
      follow: true,
      wrap: false,
      time: false,
      container: false,
      darkMode: true
    },
    appconfig: {
      visible: false,
      editable: true,
      expanded: true
    },
    persist: {
      visible: false,
      expanded: true
    },
    info: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.appDocker,
  type: 'docker'
};
export const CreateCardDocker: CustomData = {
  id: '',
  spaceId: '',
  hidden: false,
  hiddenDevice: false,
  expanded: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    status: {
      visible: true,
      expanded: true
    },
    nodes: {
      visible: true,
      expanded: true
    },
    clusterLevel: {
      visible: true,
      expanded: true
    },
    virtualMachines: {
      visible: true,
      expanded: true
    },
    log: {
      visible: true,
      expanded: true,
      enable: true,
      follow: true,
      wrap: false,
      time: false,
      container: false,
      darkMode: true
    },
    appconfig: {
      visible: true,
      editable: true,
      expanded: true
    },
    persist: {
      visible: false,
      expanded: true
    },
    info: {
      visible: true,
      expanded: true
    }
  },
  title: labelsDetail.appDocker,
  type: 'docker'
};
export const CreateCardMarketplace: CustomData = {
  id: '',
  spaceId: '',
  hidden: false,
  hiddenDevice: false,
  expanded: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    status: {
      visible: true,
      editable: true,
      expanded: true
    },
    log: {
      visible: true,
      expanded: true,
      enable: true,
      follow: true,
      wrap: false,
      time: false,
      container: false,
      darkMode: true
    },
    appconfig: {
      visible: true,
      editable: true,
      expanded: true
    },
    persist: {
      visible: false,
      editable: true,
      expanded: true
    },
    info: {
      visible: true,
      editable: true,
      expanded: true
    }
  },
  title: labelsDetail.appMarket,
  type: 'marketplace'
};
export const CreateCardModel: CustomData = {
  id: '',
  hidden: false,
  hiddenDevice: false,
  expanded: true,
  layout: {
    incompleteCommands: {
      visible: true,
      expanded: true
    },
    status: {
      visible: true,
      expanded: true
    },
    log: {
      visible: true,
      expanded: true,
      enable: true,
      follow: true,
      wrap: false,
      time: false,
      container: false,
      darkMode: true
    },
    appconfig: {
      visible: true,
      editable: true,
      expanded: true
    },
    persist: {
      visible: false,
      expanded: true
    },
    inference: {
      visible: true,
      editable: true,
      expanded: true
    },
    info: {
      visible: true,
      editable: true,
      expanded: true
    }
  },
  title: labelsDetail.model,
  type: 'model'
};
